<!--
 * @Author: mjjh
 * @LastEditTime: 2023-04-09 17:26:12
 * @FilePath: \chagpt-shuowen\src\components\UserAvatar\index.vue
 * @Description: 头像配置 自动从pinia中读取数据
-->
<script setup lang='ts'>
import { computed } from 'vue'
import { NAvatar } from 'naive-ui'
import { useUserStore } from '@/store'
import defaultAvatar from '@/assets/avatar.jpg'
import { isString } from '@/utils/is'

const userStore = useUserStore()

const userInfo = computed(() => userStore.userInfo)
</script>

<template>
  <div class="flex items-center overflow-hidden ">
    <div class="w-10 h-10 overflow-hidden  shrink-0 ">
      <template v-if="isString(userInfo.avatar) && userInfo.avatar.length > 0">
        <NAvatar
          size="large"
          :src="userInfo.avatar"
          :fallback-src="defaultAvatar"
        />
      </template>
      <template v-else>
        <NAvatar size="large"  :src="defaultAvatar" />
      </template>
    </div>
    <div class="flex-1 min-w-0 ml-2">
      <h2 class="overflow-hidden font-bold text-md text-ellipsis whitespace-nowrap">
        ID:{{ userInfo.id ?? $t('chat.defaultName') }}
      </h2>
      <h6 class="overflow-hidden text-xs text-gray-500 text-ellipsis whitespace-nowrap">
        账号:{{ userInfo.email }}
      </h6>
      <!-- <p class="overflow-hidden text-xs text-gray-500 text-ellipsis whitespace-nowrap">
        <span
          v-if="isString(userInfo.description) && userInfo.description !== ''"
          v-html="userInfo.description"
        />
      </p> -->
    </div>
  </div>
</template>
